<template>
  <container>
    <div slot="left_box_content">
      <component :is="curLeftComponent"></component>
    </div>
    <div slot="right_box_content">
      <component :is="curRightComponent" ></component>
    </div>
    <div slot="bg_map">
      <component :is="curMapComponent" ></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import stockFormatStoreModule from '@/store/modules/stock_format'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    leftComponent: () => import('./components/Left'),
    rightComponent: () => import('./components/Right'),
    centerComponent: () => import('./components/Center')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('stockFormat')) {
      this.$store.registerModule('stockFormat', stockFormatStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setLeftComponent('leftComponent')
    this.setShowRightBlock(true)
    this.setRightComponent('rightComponent')

    // 初始化其他组件部分，由左侧来控制
    this.setBottomHeight(0)
    this.setMapComponent('centerComponent')
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.unregisterModule('stockFormat')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
  }
}
</script>
